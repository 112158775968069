import React from 'react';
import IndexComponent from '../../components/common/IndexComponent';

const IndexContainer = () => {
  return (
    <div>
      <IndexComponent />
    </div>
  );
};

export default IndexContainer;