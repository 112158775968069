import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SashAccessory from '../../components/sash/SashAccessory';

const FiredoorContainer = () => {
  return (
    <SashAccessory />
  );
};

export default FiredoorContainer;